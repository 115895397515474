$slider-margin: 12px;

.carousel-main-layout {
  position: relative;

  margin-left: -$slider-margin;
  margin-right: -$slider-margin;
}

.slide-wrapper {
  margin-left: $slider-margin;
  margin-right: $slider-margin;
}
%carousel-nav-button {
  z-index: 5;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease, opacity 0.3s ease;
  background-position: center;
  opacity: 0.9;
}

.carousel-prev-button {
  @extend %carousel-nav-button;
  left: -30px;
  background-image: url("/images/chevron-small-left.svg");
}
.carousel-next-button {
  @extend %carousel-nav-button;
  right: -30px;
  background-image: url("/images/chevron-small-right.svg");
}

.carousel-prev-button:hover,
.carousel-next-button:hover {
  transform: translateY(-50%) scale(1.2);
  opacity: 1;
}
