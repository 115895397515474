@import "styles/theme.module.scss";

$fs-text: 1.5rem;

.card {
  border-radius: $border-radius-md;
  overflow: hidden;
  @include hexToRgba($darkgreen, 0.9);
  height: 240px;
  width: 100%;
  margin: 1rem;
  margin-left: auto;
  margin-right: auto;
  transition: transform 0.3s ease;
  max-width: 386px;
  a {
    text-decoration: none !important;
  }
  div {
    text-decoration: none !important;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.card:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  transform: scale(1.02);
  background-color: $darkgreen;
}

.image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.title {
  color: white;
  font-family: $playful;
  font-size: $fs-text !important;
  padding: 16px;
  margin: 0;
  font-size: 18px;
  word-wrap: break-word;
}
