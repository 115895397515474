@import "styles/theme.module.scss";

@mixin play-icon {
  width: 60px;
  cursor: pointer;
  filter: drop-shadow(0 0 20px $tertiary);
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}

.chat-column {
  position: relative;
  margin: 50px 0px;
}

.chat-icon {
  position: absolute;
  top: -90px;
  z-index: 1;
  pointer-events: none;
}

.chat-icon-arrow {
  position: absolute;
  left: -110px;
  pointer-events: none;
  z-index: 2;
}

.chat-icon-bubble {
  position: absolute;
  left: -150px;
  top: -75px;
  transition: filter 0.5s;
}

.bubble {
  cursor: pointer;
  transition: filter 0.5s;
  filter: drop-shadow(0 0 0rem white);
}

.bubble:hover {
  filter: drop-shadow(0 0 20px $tertiary);
}

.chat-icon-info {
  bottom: -10px;
  @include play-icon;
}

@keyframes neon4 {
  from {
    filter: drop-shadow(0 0 0rem white);
  }
  to {
    filter: drop-shadow(0 0 20px $tertiary);
  }
}

.kurse-icon {
  position: absolute;
  top: -110px;
  z-index: 1;
  pointer-events: none;
}

.chat-text {
  position: absolute;
  top: 66px;
  left: 35px;
  font-size: 4rem;
  font-family: "Anthology-sans";
  color: white;
  pointer-events: none;
}

.chat-bubble-text {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  color: white;
}

.meeting-bubble-text {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  color: white;
  padding: 10px;
}

.meeting-icon-bubble {
  position: absolute;
  right: -60px;
  top: -100px;
  cursor: pointer;
  transition: filter 0.5s;
  filter: drop-shadow(0 0 0px transparent);
}

.meeting-icon-bubble:hover {
  filter: drop-shadow(0 0 20px rgb(50, 106, 70));
}

.meeting-icon-arrow {
  position: absolute;
  pointer-events: none;
  right: 20px;
  z-index: 2;
}

.meeting-icon-info {
  bottom: -10px;
  @include play-icon;
}

.meeting-icon-info:hover {
  filter: drop-shadow(0 0 20px $tertiary);
}

.kurse-bubble-text {
  position: absolute;
  top: 55%;
  transform: translate(0, -50%);
  text-align: center;
  color: white;
  padding: 10px;
}

.kurse-icon-bubble {
  position: absolute;
  right: -192px;
  bottom: 0px;
  cursor: pointer;
}

.kurse-icon-arrow {
  position: absolute;
  right: -75px;
  bottom: 18px;
  pointer-events: none;
  z-index: 2;
}

.kurse-icon-info {
  bottom: -10px;
  @include play-icon;
}

.kurse-icon-info:hover {
  filter: drop-shadow(0 0 20px $tertiary);
}

// .active:not(:hover) {
//   animation: neon4 1s ease-in-out infinite alternate;
//   animation-delay: 0s;
// }

.action-column-grid {
  grid-gap: 25px;
}

@media (max-width: 778px) {
  .meeting-icon-bubble {
    display: none;
  }
  .meeting-icon-arrow {
    display: none;
  }

  .kurse-icon-bubble {
    display: none;
  }
  .kurse-icon-arrow {
    display: none;
  }
  .chat-icon-bubble {
    display: none;
  }
  .chat-icon-arrow {
    display: none;
  }
}
