.central-text-panel {
  display: table;
  margin: 0 auto;
  margin-top: 200px;
  margin-bottom: 50px;
}

.bottom-card-panel {
  display: table;
  margin: 0 auto;
  /* margin-top: 100px; */
}

.chat-bot-icon {
  position: sticky;
  right: 9px;
  bottom: 15px;
  width: 100px;
  float: right;
}

.main-layout {
  padding: 0 10vw;
  margin-top: 40px;
}

.disruptor-wrapper {
  z-index: 500;
  position: fixed;
  right: 1rem;

  @media (max-width: 778px) {
    bottom: 7rem;
    right: 1rem;
  }

  @media (min-width: 779px) {
    top: 8.5rem;
  }
}
