$primary: #7ab829;
$secondary: #696871;
$tertiary: #75c5bf;
$yellow: rgba(240, 228, 0, 1);
$blue: #75c5bf;
$red: #ec5e40;
$darkgreen: #1a6c41;
$playful: "Anthology-Sans" !important;
$standard: "sofia-pro" !important;
$border-radius-sm: 3px;
$border-radius-md: 5px;

@mixin hexToRgba($hex, $alpha) {
  background-color: rgba(red($hex), green($hex), blue($hex), $alpha);
}
