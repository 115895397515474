@import "styles/theme.module.scss";

$fs-header-big: 3rem;
$fs-header-medium: 2.5rem;
$fs-header-small: 2.2rem;
$fs-button-big: 2rem;
$fs-text: 1.5rem;
$fs-text-small: 1.2rem;

@mixin header-style {
  font-family: $playful;
}

@mixin block-style {
  font-size: $fs-text;
  font-family: $standard;
}

%button {
  margin-top: 1rem;
  font-family: $playful;
  font-size: $fs-text-small;
  background-color: $primary;
  border-radius: $border-radius-md;
  color: white;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
}

.main-layout {
  margin-top: 3rem;
  margin-bottom: 6rem;
  padding: 0 10vw;

  h1 {
    @include header-style;
    margin-bottom: 1.5rem;
    text-align: center;
    font-size: 5.5rem;
    color: $secondary;

    @media (min-width: 769px) and (max-width: 1400px) {
      font-size: 4.5rem;
    }

    @media (max-width: 768px) {
      font-size: 3.5rem;
    }
  }

  h2 {
    @include header-style;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: left;
    font-size: $fs-header-big;
    color: $primary;

    @media (max-width: 768px) {
      font-size: $fs-header-small;
      word-break: break-word;
    }
    @media (min-width: 769px) and (max-width: 1200px) {
      font-size: $fs-header-medium;
    }
  }

  h3 {
    @include header-style;
    font-size: $fs-header-big;

    @media (max-width: 768px) {
      font-size: $fs-header-small;
      word-break: break-word;
    }
    @media (min-width: 769px) and (max-width: 1200px) {
      font-size: $fs-header-medium;
    }
  }

  > div {
    padding: 30px 40px;
  }

  a {
    color: $primary;
  }

  @media (max-width: 768px) {
    margin-top: 1.5rem;
  }
}

.intro-block {
  @include block-style;
  background-color: white;
  color: $secondary;
  border-top-left-radius: $border-radius-md;
  border-top-right-radius: $border-radius-md;
  text-align: left;
  box-shadow: inset 0px 0.5px 2px 0.5px lightgrey;

  .first-h2 {
    margin-top: 0.5rem;
  }

  .call-to-action {
    text-align: center;
    margin-bottom: 2rem;
  }

  button {
    @extend %button;
    font-size: $fs-button-big;

    @media (max-width: 1575px) {
      font-size: $fs-text;
    }
  }

  @mixin pixelchen-placeholder {
    width: 500px;
    margin-right: -150px;
    margin-left: -50px;

    @media (min-width: 1201px) and (max-width: 1575px) {
      width: 350px;
    }
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .placeholder {
    @include pixelchen-placeholder;
    display: inline-block;
  }

  .pixelchen {
    @include pixelchen-placeholder;

    margin-top: -50px;
    margin-bottom: -50px;
    transform: scaleX(-1) rotate(-15deg);
  }

  .arrow-highlight-left {
    transform: rotate(125deg);
    margin-right: 1rem;
    width: 130px;

    @media (max-width: 815px) {
      display: none;
    }
  }

  .arrow-highlight-right {
    transform: rotate(10deg);
    margin-left: 1rem;
    width: 130px;

    @media (max-width: 815px) {
      display: none;
    }
  }
}

.block-yellow {
  @include block-style;
  background-color: $yellow;
  color: $secondary;
  display: grid;
  grid-template-columns: 1fr auto;

  .video-container {
    width: 550px;
    margin: 0.8rem 0rem 2rem 4rem;
    display: flex;
    align-items: flex-start;
    justify-content: start;
    border-radius: $border-radius-md;
    overflow: hidden;
    grid-column: 2;
  }

  @media (max-width: 1250px) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-row-gap: 20px;
    grid-column-gap: 0;

    .video-container {
      width: 100%;
      margin-left: 0;
      grid-column: initial;
    }
  }
}

.block-green {
  @include block-style;
  background-color: $darkgreen;
  color: white;
}

.block-teal {
  @include block-style;
  background-color: $tertiary;
  color: white;

  min-height: 450px;

  a {
    color: white;
    text-decoration: underline;
  }

  button {
    @extend %button;
  }

  .video-column {
    width: 550px;
    float: left;
    display: flex;
    flex-direction: column;
    margin: 0.8rem 4rem 1rem 0;
  }

  .video-container {
    width: 100%;
    border-radius: $border-radius-md;
    overflow: hidden;
  }

  .video-subtitle {
    font-size: $fs-text-small;
  }

  @media (max-width: 1250px) {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    grid-row-gap: 20px;
    grid-column-gap: 0;

    .video-column {
      float: none;
      width: 100%;
      grid-row: 2;
      margin-right: 0;
    }

    button {
      margin: 0 auto;
      display: block;
    }
  }
}

.block-license {
  background-color: white;
  color: $secondary;
  border-bottom-left-radius: $border-radius-md;
  border-bottom-right-radius: $border-radius-md;
  font-size: $fs-text-small;
  font-family: $standard;
  text-align: left;
  box-shadow: inset 0px 0.5px 2px 0.5px lightgrey;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.collection-card-wrapper {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
  text-align: center;

  a {
    text-decoration: none;
  }
}
