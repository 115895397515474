@import "styles/theme.module.scss";

.text {
  font-size: 2.5rem;
  background-color: white;
  border-radius: $border-radius-md;
  padding: 30px 40px;
}

.button {
  background-color: $primary;
  border-radius: $border-radius-md;
  height: 52px;
  color: white;
  font-size: 18px;
  border: none;
  padding: 10px 20px;
  font-family: $playful;
}

.text-1 {
  /* white-space: nowrap; */
  text-align: center;
  color: $secondary;
}

.text-2 {
  font-size: 2rem;
  text-align: center;
  color: $primary;
  /* white-space: nowrap; */
}

.logo {
  vertical-align: bottom;
  transform: translateX(-25%);
  position: absolute;
  z-index: -1;
  height: 165px;
  bottom: 0;
}

.people {
  z-index: 1;
  object-fit: contain;
  height: 240px;

  transition: opacity 0.5s ease-in-out;

  &.fade-away {
    opacity: 0;
  }
}

@media (max-width: 778px) {
  .people {
    height: unset;
    max-width: 100vw;
  }
}

.container {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.people-images {
  position: absolute;
  transform: translateY(-100%) translateX(-50%);
  left: 50%;
}

@media (max-width: 778px) {
  .text {
    font-size: 1.5rem;
  }
}

.modal {
  font-family: $standard;
}
