@import "styles/theme.module.scss";

.root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > span > span:nth-child(1) {
    background-color: $tertiary;
  }
}
