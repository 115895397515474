.card {
  /* width: 600px; */
  background-color: #ffffff;
  padding: 24px;
  position: relative;
  margin: 50px 25px;

  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.title h2 a {
  font-size: calc(2rem + 1.5vw) !important;
  letter-spacing: -3.6px;
  color: #707070;
  margin-bottom: 1rem;
  line-height: 1em;
  white-space: break-spaces;
  text-decoration: none;
}

.text {
  color: #707070;
  font-family: sofia-pro, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.card .text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left-bubble {
  background-image: url("/images/bottom-section/FM_Textbutton.svg");
  color: white;
  background-size: contain;
  position: absolute;
  top: 0;
  right: -59px;
  z-index: 95;
  height: 211px;
  width: 300px;
  transform: translateY(-50%);
  display: flex;
  vertical-align: middle;
  background-repeat: no-repeat;
  text-decoration: none; /* no underline */
}

.left-bubble:hover {
  color: white;
}

.right-bubble:hover {
  color: white;
}

@media (max-width: 1130px) and (min-width: 600px) {
  .left-bubble {
    top: -60px;
  }
}

.left-bubble-arrow {
  position: absolute;
  top: -65px;
  right: 165px;
}

.left-bubble-text {
  font-family: "Anthology-Sans" !important;
  position: relative;
  font-size: 1.3rem;
  left: 13%;
  top: 36%;
  text-align: center;
  height: min-content;
}

.right-bubble {
  background-image: url("/images/bottom-section/LE_Textbutton.svg");
  color: white;

  background-size: contain;

  position: absolute;
  top: -20px;
  right: 0px;
  z-index: 95;
  height: 211px;
  width: 213px;
  transform: translateY(-50%);
  display: flex;
  vertical-align: middle;
  text-decoration: none; /* no underline */
  background-repeat: no-repeat;
}

.right-bubble-text {
  font-family: "Anthology-Sans" !important;
  position: relative;
  font-size: 1.2rem;
  left: 25%;
  top: 15%;
  text-align: center;
  height: min-content;
}

.right-bubble-arrow {
  position: absolute;
  top: -25px;
  right: 80px;
}

@media (max-width: 1100px) {
  .right-bubble-arrow {
    display: none;
  }
  .left-bubble-arrow {
    display: none;
  }
}

@media (max-width: 600px) {
  .left-bubble-arrow {
    display: none;
  }

  .title {
    font-size: calc(2rem);
  }

  .left-bubble {
    width: 195px;
    right: -15px;
  }

  .left-bubble-text {
    font-size: 1rem !important;
    top: 23% !important;
  }

  .right-bubble {
    width: 170px;
    top: 35px;
    /* display: none; */
  }

  .right-bubble-text {
    font-size: 0.8rem;
    top: 17%;
  }

  .right-bubble-arrow {
    display: none;
  }
}
